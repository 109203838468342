import { Trans } from "@lingui/macro";
import ExternalLink from "components/ExternalLink/ExternalLink";
import React, { useState } from "react";
import cx from "classnames";
import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
`;

const ModalContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  z-index: 9999;
  width: 600px;
  height: 400px;
  background: transparent;
  border-radius: 8px; 
`;


const ModalIframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

const StyledButton = styled.button`
  background-color: inherit;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: underline;
  border: none;
  cursor: pointer;
  text-color: inherit;
  padding-left: 0px;
`;



export default function UsefulLinks({ className }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const classNames = cx("Exchange-swap-market-box App-box App-box-border", className);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  return (
    <>
      <div className={classNames}>
        <div className="Exchange-swap-market-box-title">
          <Trans>Useful Links</Trans>
        </div>
        <div className="App-card-divider"></div>
        <div className="Exchange-info-row">
          <div className="Exchange-info-label-button">
          <StyledButton onClick={() => openModal()}>
            <Trans>Bridge assets with Layerswap!</Trans>
          </StyledButton>
          </div>
        </div>
        <div className="Exchange-info-row">
          <div className="Exchange-info-label-button">
            <ExternalLink href="https://dovish.gitbook.io/shrike/trading">
              <Trans>Trading guide</Trans>
            </ExternalLink>
          </div>
        </div>
        <div className="Exchange-info-row">
          <div className="Exchange-info-label-button">
            <ExternalLink href="https://dovish.gitbook.io/shrike/trading#backup-rpc-urls">
              <Trans>Speed up page loading</Trans>
            </ExternalLink>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <ModalOverlay onClick={(e) => handleOverlayClick(e)}>
          <span>Loading...</span>
          <ModalContent>
            <ModalIframe src="https://layerswap.io?destNetwork=POLYGONZK_MAINNET&lockNetwork=true" title="Modal" />
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
}
