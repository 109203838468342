import * as React from "react";
import "./index.css";
import Datafeed from "./api";

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, " "));
}

let tvWidget = null

export default function TVChartContainer(props) {
  const ref = React.useRef()

  React.useEffect(() => {
    const widgetOptions = {
      symbol: props.symbol,
      datafeed: Datafeed,
      interval: props.interval,
      theme: "dark",
      container: ref.current,
      library_path: props.libraryPath,
      locale: getLanguageFromURL() || "en",
      disabled_features: ["use_localstorage_for_settings", "header_symbol_search","header_saveload", "left_toolbar", "header_compare"],
      enabled_features: [],
      charts_storage_url: props.chartsStorageUrl,
      charts_storage_api_version: props.chartsStorageApiVersion,
      client_id: props.clientId,
      user_id: props.userId,
      fullscreen: props.fullscreen,
      autosize: props.autosize,
      visible_plots_set: "ohlc",
      studies_overrides: props.studiesOverrides,
      custom_css_url: "./themed.css",
      overrides: {
        // "mainSeriesProperties.showCountdown": true,
        "paneProperties.backgroundGradientStartColor": "#272727",
        "paneProperties.backgroundGradientEndColor": "#272727",
        "paneProperties.vertGridProperties.color": "#444",
        "paneProperties.horzGridProperties.color": "#444",
        // "symbolWatermarkProperties.transparency": 90,
        // "scalesProperties.textColor": "#AAA",
        "mainSeriesProperties.candleStyle.wickUpColor": "#63B48E", // green
        "mainSeriesProperties.candleStyle.wickDownColor": "#EC605A", // red
      },
      time_frames: [
        { text: "1y", resolution: "1D", description: "1 Year" },
        { text: "6m", resolution: "240", description: "6 Months" },
        { text: "3m", resolution: "240", description: "3 Months" },
        { text: "1m", resolution: "60", description: "1 Month" },
        { text: "5d", resolution: "15", description: "5 Days" },
        { text: "1d", resolution: "5", description: "1 Day" },
      ],
    };
    const _tvWidget = (window.tvWidget = new window.TradingView.widget(widgetOptions));

    tvWidget = _tvWidget;

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        // console.log("Chart has loaded!");
      });
    });

    return () => {
      if (tvWidget !== null) {
        tvWidget.remove();
        tvWidget = null;
      }
    }
  }, [props.symbol])

  return <div ref={ref} className={"TVChartContainer"} />;
}

TVChartContainer.defaultProps = {
  symbol: "BTC/USD",
  interval: "15",
  libraryPath: "/charting_library/",
  chartsStorageUrl: "",
  chartsStorageApiVersion: "1.1",
  clientId: "shrike",
  userId: "shrike",
  fullscreen: false,
  autosize: true,
  studiesOverrides: {},
};
