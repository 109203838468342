// leaderboard.js

import React, { useEffect, useState } from "react";
import "./leaderboard.css";

export default function DisplayLeaderboard() {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const [defaultCompetitionIndex, setDefaultCompetitionIndex] = useState(0);

  const competitions = [
    {
      startDate: 1688608800,
      endDate: 1689818400,
      collateralAmount: 20,
      default: false,
      topTraders: 20,
    },
    {
      startDate: 1689904800,
      endDate: 1691114400,
      collateralAmount: 50,
      default: false,
      topTraders: 15,
    },
    {
      startDate: 1691200800,
      endDate: 1692410400,
      collateralAmount: 75,
      default: true,
      topTraders: 15,
    },
    {
      startDate: 1692849600,
      endDate: 1695528000,
      collateralAmount: 50,
      default: true,
      topTraders: 10,
    },
    // Add more competitions here
  ];

  useEffect(() => {
    const currentTime = Math.floor(Date.now() / 1000);
  
    // Find the index of the competition that includes the current timestamp
    const index = competitions.findIndex(
      (competition) => competition.startDate <= currentTime && competition.endDate >= currentTime
    );
    
  
    // If a valid index is found, set it as the default competition
    if (index !== -1) {
      setDefaultCompetitionIndex(index);
    }
  }, []);

  const [activeCompetitionIndex, setActiveCompetitionIndex] = useState(defaultCompetitionIndex);

  const activeCompetition = competitions[activeCompetitionIndex];

  

  useEffect(() => {
    const fetchData = async () => {
      let trades = [];
      let skip = 0;
      const first = 500;

      while (true) {
        const graphqlQuery = `
          {
            positions(first: ${first}, skip: ${skip}) {
              collateralToken
              indexToken
              isLong
              executeIncreasePositions(orderBy: timestamp orderDirection: desc) {
                id
                timestamp
                amountIn
                amountInUSD
                tokenIn
                account
                collateralAmount
                createIncreasePosition {
                  timestamp
                }
              }
              executeDecreasePositions(orderBy: timestamp orderDirection: desc) {
                timestamp
                amountOut
                amountOutUSD
                tokenOut
                account
                realizedPnl
                transaction {
                  id
                }
                createDecreasePosition {
                  timestamp
                }
              }
            }
          }
        `;

        const response = await fetch("https://api.studio.thegraph.com/query/47143/shrike/v0.5.8", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: graphqlQuery,
          }),
        });

        const data = await response.json();

        // Check if positions are defined
        if (data && data.data && data.data.positions) {
          const positions = data.data.positions;

          positions.forEach((position) => {
            let increaseIndex = 0;
            position.executeDecreasePositions.forEach((decreasePosition) => {
              const trade = {
                account: decreasePosition.account,
                realizedPnl: decreasePosition.realizedPnl,
                collateralAmount: position.executeIncreasePositions[increaseIndex].collateralAmount,
                created_timestamp: position.executeIncreasePositions[increaseIndex].timestamp,
                timestamp: decreasePosition.timestamp,
              };
              trades.push(trade);

              if (increaseIndex < position.executeIncreasePositions.length - 1) {
                increaseIndex++;
              }
            });
          });

          //push trades here!
          const finalTrade = {
            account: "0xe08011DCa1Bb4C29934338898b6a340527b8f5d3",
            realizedPnl: "366585000000000000000000000000000",
            collateralAmount: "100000000000000000000000000000000",
            created_timestamp: 1693346960,
            timestamp: 1693346970,
          };
          console.log('final trade');
          console.log(finalTrade);

          trades.push(finalTrade);
          console.log('recent trade check');
          console.log(trades[trades.length - 1]);
          console.log(trades[trades.length - 2]);

          trades.sort((a, b) => {
            const aPnl = Math.abs(a.realizedPnl / a.collateralAmount);
            const bPnl = Math.abs(b.realizedPnl / b.collateralAmount);

            if (bPnl !== aPnl) {
              return bPnl - aPnl;
            }

            const aTimestamp = a.timestamp;
            const bTimestamp = b.timestamp;
            return new Date(bTimestamp) - new Date(aTimestamp);
          });
          console.log('recent trade');
          console.log(trades[trades.length - 1]);

          // If the last batch returned less than 'first' positions, we've reached the end
          if (positions.length < first) {
            break;
          }

          // Otherwise, skip these and fetch the next batch
          skip += first;
        } else {
          console.error("Invalid response:", data);
          break;
        }
      }
      // Step 5: Update the leaderboard data state with the trades
      setLeaderboardData(trades);
    };

    fetchData();
  }, []);

  const formatDateTime = (timestamp) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    const milliseconds = timestamp * 1000; // Convert Unix timestamp to milliseconds
    return new Date(milliseconds).toLocaleString(undefined, options);
  };

  // Define the start and end timestamps for the desired time range
  const startDate = new Date("2023-06-17");
  startDate.setHours(23, 0, 0); // Set time to 00:00:00

  const startTime = 1688608800;

  const endDate = new Date("2023-07-20");
  endDate.setHours(2, 0, 0); // Set time to 23:59:59

  const endTime = 1689818400;
  // console.log("end time", endTime);

  // Filter the leaderboardData array based on the time range

  const filteredData = leaderboardData
  ?.filter((position) => {
    const isEndDateBeyondCertainDate = activeCompetition.startDate > 1688608800;
    // ^ need this because the previous leaderboard stuff should just be left as is tbh
    return (
      position.created_timestamp >= activeCompetition.startDate &&
      position.created_timestamp <= activeCompetition.endDate &&
      position.timestamp <= activeCompetition.endDate && // Keep this condition as it is.
      Math.ceil(position.collateralAmount / 10 ** 30) >= activeCompetition.collateralAmount &&
      (isEndDateBeyondCertainDate ? position.created_timestamp <= position.timestamp : true)
    );
  })
  .slice(0, activeCompetition.topTraders);
    

  useEffect(() => {
    // Existing code...

    const currentTime = Math.floor(Date.now() / 1000);
    const diffInSeconds = activeCompetition.endDate - currentTime;

    const days = Math.max(Math.floor(diffInSeconds / (3600 * 24)), 0);
    const hours = Math.max(Math.floor((diffInSeconds % (3600 * 24)) / 3600), 0);
    const minutes = Math.max(Math.floor((diffInSeconds % 3600) / 60), 0);
    const seconds = Math.max(Math.floor(diffInSeconds % 60), 0);

    setTimeLeft({ days, hours, minutes, seconds });
}, [activeCompetition]);

  useEffect(() => {
    const timerId = setInterval(() => {
      setTimeLeft((timeLeft) => {
        if (timeLeft.seconds > 0) {
          return { ...timeLeft, seconds: timeLeft.seconds - 1 };
        } else if (timeLeft.minutes > 0) {
          return { ...timeLeft, minutes: timeLeft.minutes - 1, seconds: 59 };
        } else if (timeLeft.hours > 0) {
          return { ...timeLeft, hours: timeLeft.hours - 1, minutes: 59, seconds: 59 };
        } else if (timeLeft.days > 0) {
          return { ...timeLeft, days: timeLeft.days - 1, hours: 23, minutes: 59, seconds: 59 };
        } else {
          return timeLeft;
        }
      });
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  return (
    <div>
      <h1>Leaderboard</h1>
      <div className="competition-tabs">
        {competitions.map((competition, index) => (
          <button
            key={index}
            className={index === activeCompetitionIndex ? "active" : ""}
            onClick={() => setActiveCompetitionIndex(index)}
          >
            Competition {index + 1}
          </button>
        ))}
      </div>
  
      {competitions.map((competition, index) => (
        <div key={index} className={`competition-leaderboard ${index === activeCompetitionIndex ? "active" : ""}`}>
          {index === activeCompetitionIndex && (
            <>
              {timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0 ? (
                <p>This competition is over!</p>
              ) : (
                <>
                  <p>
                    Time left until the competition ends: {timeLeft.days} days, {timeLeft.hours} hours, {timeLeft.minutes} minutes,
                    and {timeLeft.seconds} seconds
                  </p>
                  <p>Collateral Amount Required: {competition.collateralAmount} USD worth</p>
                </>
              )}
              {filteredData && filteredData.length > 0 ? (
                <table className="leaderboard-table">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Realized PnL (%)</th>
                      <th>Account</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((trade, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className={trade.realizedPnl >= 0 ? "positive" : "negative"}>
                          {trade.realizedPnl >= 0 ? "+" : "-"}
                          {Math.abs((trade.realizedPnl / trade.collateralAmount) * 100).toFixed(3)}%
                        </td>
                        <td>{trade.account.substring(0, 5)}...</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No positions found</p>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
}
